import { Routes } from '@angular/router';

// prettier-ignore
const programSearchPageRoutes: Routes = [
  {
    path: 'algeria-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'algeria-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'algeria-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'algeria-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'argentina-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'argentina-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'argentina-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'argentina-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'armenia-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'armenia-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'armenia-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'armenia-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'australia-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'australia-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'australia-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'australia-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'austria-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'austria-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'austria-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'austria-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'azerbaycan-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'azerbaycan-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'azerbaycan-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'azerbaycan-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'bahrain-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'bahrain-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'bahrain-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'bahrain-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'belgium-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'belgium-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'belgium-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'belgium-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'bhutan-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'bhutan-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'bhutan-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'bhutan-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'brazil-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'brazil-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'brazil-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'brazil-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'brunei-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'brunei-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'brunei-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'brunei-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'bulgaria-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'bulgaria-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'bulgaria-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'bulgaria-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'cambodia-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'cambodia-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'cambodia-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'cambodia-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'canada-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'canada-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'canada-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'canada-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'chile-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'chile-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'chile-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'chile-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'china-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'china-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'china-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'china-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'cyprus-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'cyprus-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'cyprus-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'cyprus-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'czech-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'czech-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'czech-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'czech-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'denmark-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'denmark-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'denmark-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'denmark-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'ecuador-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'ecuador-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'ecuador-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'ecuador-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'egypt-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'egypt-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'egypt-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'egypt-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'england-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'england-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'england-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'england-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'estonia-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'estonia-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'estonia-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'estonia-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'europe-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'europe-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'europe-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'europe-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'finland-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'finland-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'finland-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'finland-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'france-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'france-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'france-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'france-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'georgia-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'georgia-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'georgia-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'georgia-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'germany-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'germany-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'germany-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'germany-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'greece-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'greece-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'greece-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'greece-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'greenland-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'greenland-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'greenland-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'greenland-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'hong-kong-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'hong-kong-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'hong-kong-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'hong-kong-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'hungary-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'hungary-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'hungary-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'hungary-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'iceland-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'iceland-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'iceland-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'iceland-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'india-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'india-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'india-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'india-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'indonesia-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'indonesia-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'indonesia-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'indonesia-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'iran-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'iran-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'iran-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'iran-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'ireland-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'ireland-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'ireland-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'ireland-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'israel-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'israel-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'israel-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'israel-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'italy-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'italy-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'italy-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'italy-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'japan-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'japan-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'japan-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'japan-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'jordan-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'jordan-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'jordan-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'jordan-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'kazakhstan-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'kazakhstan-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'kazakhstan-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'kazakhstan-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'laos-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'laos-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'laos-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'laos-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'latvia-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'latvia-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'latvia-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'latvia-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'lithuania-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'lithuania-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'lithuania-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'lithuania-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'luxembourg-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'luxembourg-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'luxembourg-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'luxembourg-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'macau-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'macau-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'macau-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'macau-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'malaysia-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'malaysia-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'malaysia-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'malaysia-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'maldives-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'maldives-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'maldives-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'maldives-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'mongolia-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'mongolia-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'mongolia-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'mongolia-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'montenegro-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'montenegro-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'montenegro-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'montenegro-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'morocco-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'morocco-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'morocco-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'morocco-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'myanmar-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'myanmar-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'myanmar-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'myanmar-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'nepal-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'nepal-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'nepal-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'nepal-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'netherlands-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'netherlands-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'netherlands-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'netherlands-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'new-zealand-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'new-zealand-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'new-zealand-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'new-zealand-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'norway-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'norway-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'norway-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'norway-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'panama-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'panama-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'panama-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'panama-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'peru-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'peru-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'peru-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'peru-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'philippines-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'philippines-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'philippines-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'philippines-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'poland-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'poland-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'poland-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'poland-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'portugal-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'portugal-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'portugal-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'portugal-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'republic-of-croatia-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'republic-of-croatia-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'republic-of-croatia-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'republic-of-croatia-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'romania-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'romania-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'romania-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'romania-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'russia-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'russia-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'russia-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'russia-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'rwanda-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'rwanda-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'rwanda-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'rwanda-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'scotland-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'scotland-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'scotland-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'scotland-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'serbia-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'serbia-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'serbia-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'serbia-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'seychelles-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'seychelles-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'seychelles-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'seychelles-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'singapore-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'singapore-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'singapore-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'singapore-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'slovakia-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'slovakia-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'slovakia-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'slovakia-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'slovenia-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'slovenia-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'slovenia-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'slovenia-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'south-africa-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'south-africa-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'south-africa-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'south-africa-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'south-korea-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'south-korea-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'south-korea-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'south-korea-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'spain-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'spain-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'spain-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'spain-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'sri-lanka-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'sri-lanka-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'sri-lanka-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'sri-lanka-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'sweden-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'sweden-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'sweden-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'sweden-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'switzerland-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'switzerland-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'switzerland-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'switzerland-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'taiwan-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'taiwan-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'taiwan-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'taiwan-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'thailand-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'thailand-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'thailand-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'thailand-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'tunisia-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'tunisia-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'tunisia-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'tunisia-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'turkey-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'turkey-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'turkey-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'turkey-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'uganda-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'uganda-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'uganda-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'uganda-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'united-arab-emirates-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'united-arab-emirates-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'united-arab-emirates-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'united-arab-emirates-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'united-states-of-america-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'united-states-of-america-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'united-states-of-america-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'united-states-of-america-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'vanuatu-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'vanuatu-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'vanuatu-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'vanuatu-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'vietnam-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'vietnam-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'vietnam-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'vietnam-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'wales-tour',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'wales-tour/:country',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'wales-tour/:country/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
  {
    path: 'wales-tour/:country/:city/:filter',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent)
  },
];
// prettier-ignore
const errorPage: Routes = [
  {
    path: '404',
    loadComponent: () => import('./pages/page-not-found/page-not-found.component').then((c) => c.PageNotFoundComponent)
  },
];
// prettier-ignore
export const routes: Routes = [
  ...programSearchPageRoutes,
  ...errorPage,
  {
    path: 't/:slug',
    loadComponent: () => import('./pages/prebooking-page/prebooking-page.component').then((c) => c.PrebookingPageComponent),
  },
  {
    path: 'order/:slug',
    children: [
      {
          path: '',
          loadComponent: () => import('./pages/order-detail-page/order-detail-page.component').then((c) => c.OrderDetailPageComponent),
      },
      {
          path: 'passport/:passportkey',
          loadComponent: () => import('./pages/passport-download/passport-download.component').then((c) => c.PassportDownloadComponent),
      },
      {
          path: 'visa/:visakey',
          loadComponent: () => import('./pages/visa-download/visa-download.component').then((c) => c.VisaDownloadComponent),
      },
      {
          path: 'notfound',
          loadComponent: () => import('./pages/not-found/not-found.component').then((c) => c.NotFoundComponent),
      },
  ],
  },
  {
    path: 'gallery',
    loadComponent: () => import('./pages/gallery-list-page/gallery-list-page.component').then((c) => c.GalleryListPageComponent),
  },
  {
    path: 'gallery/:id',
    loadComponent: () => import('./pages/gallery-detail-page/gallery-detail-page.component').then((c) => c.GalleryDetailPageComponent),
  },
  {
    path: 'about-us',
    loadComponent: () => import('./pages/about-us-page/about-us-page.component').then((c) => c.AboutUsPageComponent),
  },
  {
    path: 'about-us/profile/:id',
    loadComponent: () => import('./pages/agency-profile-page/agency-profile-page.component').then((c) => c.AgencyProfilePageComponent),
  },
  {
    path: 'contact-us',
    loadComponent: () => import('./pages/contact-us-page/contact-us-page.component').then((c) => c.ContactUsPageComponent),
  },
  {
    path: 'terms',
    loadComponent: () => import('./pages/terms-page/terms-page.component').then((c) => c.TermsPageComponent),
  },
  {
    path: 'privacy-policy',
    loadComponent: () => import('./pages/privacy-policy-page/privacy-policy-page.component').then((c) => c.PrivacyPolicyPageComponent),
  },
  {
    path: 'hot-promotion',
    loadComponent: () => import('./pages/hot-promotion-page/hot-promotion-page.component').then((c) => c.HotPromotionPageComponent),
  },
  {
    path: 'promotion/:slug',
    loadComponent: () => import('./pages/double-date-promotion-page/double-date-promotion-page.component').then((c) => c.DoubleDatePromotionPageComponent),
  },
  {
    path: 'blog/:slug',
    loadComponent: () => import('./pages/blog-detail-page/blog-detail-page.component').then((c) => c.BlogDetailPageComponent),
  },
  {
    path: 'blog',
    loadComponent: () => import('./pages/blog-list-page/blog-list-page.component').then((c) => c.BlogListPageComponent),
  },
  {
    path: 'tours',
    loadComponent: () => import('./pages/program-search-page/program-search-page.component').then((c) => c.ProgramSearchPageComponent),
  },
  {
    path: 'tours/:slug',
    loadComponent: () => import('./pages/program-detail-page/program-detail-page.component').then((c) => c.ProgramDetailPageComponent),
  },
  {
    path: 'testimonial',
    loadComponent: () => import('./pages/testimonial-page/testimonial-page.component').then((c) => c.TestimonialPageComponent),
  },
  {
    path: 'booking/new',
    loadComponent: () => import('./pages/new-booking-page/new-booking-page.component').then((c) => c.NewBookingPageComponent),
  },
  {
    path: 'member/gallery/:member_key',
    loadComponent: () => import('./pages/member-gallery-page/member-gallery-page.component').then((c) => c.MemberGalleryPageComponent),
  },
  {
    path: 'member/gallery/:member_key/page/:gallery_key',
    loadComponent: () => import('./pages/member-gallery-detail-page/member-gallery-detail-page.component').then((c) => c.MemberGalleryDetailPageComponent),
  },
  {
    path: '',
    loadComponent: () => import('./pages/home-page/home-page.component').then((c) => c.HomePageComponent),
  },
  {
    path: '**',
    loadComponent: () => import('./pages/page-not-found/page-not-found.component').then((c) => c.PageNotFoundComponent),
  },
];
